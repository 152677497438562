import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContentR5Box } from '../../themes/boxLayout'
import { CertLeftBox } from '../../themes/boxCert'
import { certItems, certOptions } from '../../data/cert'
import { certHexagonImage, certHexagonActiveImage, certHexagonMainImage } from '../../themes/image'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { constants } from '../../data/constants'
import { apiCert } from '../../helpers/urls'
import { sortByPriority } from '../../helpers/array'
import useWindowDimensions from '../../helpers/dimensions'
import ContentButton from '../form/ContentButton'
import ModalWindow from '../layout/ModalWindow'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'

const Cert = () => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [items, setItems] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [textOpacity, setTextOpacity] = useState(1)
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')

  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  const sxMobileItem = width > 600 ? {
    width: 'calc(100% - 148px)',
    fontSize: '13px',
    paddingLeft: '20px',
    fontFamily: theme.typography.regular.fontFamily,
  } : {
    width: '100%',
    fontSize: '13px',
    paddingLeft: '0px',
    fontFamily: theme.typography.regular.fontFamily,
  }

  const sxMain = {
    background: 'linear-gradient(116.17deg, rgba(7, 77, 120, 0.8) 0.95%, rgba(7, 77, 120, 0.05) 86.75%)',
    color: theme.palette.offwhite.main,
  }

  const sxSecondary = {
    background: '#476E84',
    color: theme.palette.offwhite.main,
  }

  const sxButton = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    fontSize: '20px',
    color: theme.palette.offwhite.main,
    background: theme.palette.primary.main,
    textTransform: theme.typography.mainmenu.textTransform,
    borderRadius: '8px',
    padding: '0px 100px',
    marginBottom: '30px',
    height: '92px',
    boxShadow: 'none',

    '&:hover': {
      background: 'rgba(7, 77, 120, 0.9)',
      boxShadow: 'none',
    },

    '@media(max-width: 899px)': {
      width: '100%',
      height: 'auto',
      padding: '10px',
      fontSize: '18px',
    }
  }

  const handleClick = (index) => {
    if (index !== activeIndex) {
      setTextOpacity(0)
      setTimeout(() => {
        setActiveIndex(index)
        setTextOpacity(1)
      }, 300)
    }
  }

  const handleMouseEnter = (index) => {
    setHoverIndex(index)
  }

  const handleMouseLeave = (index) => {
    if (index !== activeIndex) setHoverIndex(-1)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const fetchItems = async () => {
      const response = await request(settings.domain + settings.api + apiCert.getItems, 'GET')

      if (response && response.length > 0) {
        setItems(response)
      } else {
        setItems([])
      }
    }

    if (settings.mode === 'live') {
      fetchItems()
    } else {
      setItems(sortByPriority(certItems))
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    if (items.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [items])

  return (
    <RegularPageFluidBox>
      <BackdropElement open={backdropOpen} />
      <ErrorMessage open={errorMessageOpen} />
      <Container maxWidth='xl' sx={sxContainer}> 
        <Grid container>
          <Grid xs={12} lg={5}>
            <RegularPageTitleBox>CERT-GE</RegularPageTitleBox>
          </Grid>
        </Grid>
        <RegularPageContentR5Box>
          {
            width > settings.desktopBreakpoint ? (
              <Grid container>
                <Grid lg={6}>
                  <CertLeftBox sx={ activeIndex === 0 ? sxMain : sxSecondary }>
                    <Box sx={{ opacity: textOpacity, transition: 'opacity 0.3s' }}>
                      { activeIndex !== 0 && <Box sx={{ fontSize: '20px', paddingBottom: '30px' }}>{items[activeIndex].title[lang]}</Box> }
                      <Box>{ items.length > 0 && items[activeIndex].description[lang] }</Box>
                    </Box>
                  </CertLeftBox>
                </Grid>
                <Grid lg={5} lgOffset={1}>
                  <Box sx={{ position: 'relative', minHeight: '500px', }}>
                    {
                      items.map((item, index) => {
                        let bgImage = certHexagonImage
                        if (index === 0) bgImage = certHexagonMainImage
                        else if (index === activeIndex || index === hoverIndex) bgImage = certHexagonActiveImage

                        const fontColor = (index === 0 || index === activeIndex || index === hoverIndex) ? theme.palette.darkblue.main : theme.palette.offwhite.main
                        const fontSize = index === 0 ? '16px' : '15px'

                        return (
                          <Box
                            key={index}
                            sx={{
                              fontFamily: theme.typography.regular.fontFamily,
                              fontSize: fontSize,
                              color: fontColor,
                              lineHeight: '18px',
                              padding: '0px 10px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'center',
                              position: 'absolute',
                              cursor: 'pointer',
                              width: certOptions[index].width,
                              height: certOptions[index].height,
                              top: certOptions[index].top,
                              left: certOptions[index].left,
                              background: `url(${bgImage}) no-repeat center`,
                              transition: 'all 0.2s',
                            }}
                            onClick={() => handleClick(index)}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                          >
                            {item.title[lang]}
                          </Box>
                        )
                      })
                    }
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Box sx={{ marginBottom: '50px' }}>
                {
                  items.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '30px', }}>
                      {
                        width > 600 && (
                          <Box
                            sx={{
                              fontFamily: theme.typography.regular.fontFamily,
                              fontSize: '15px',
                              color: theme.palette.offwhite.main,
                              lineHeight: '18px',
                              padding: '0px 10px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'center',
                              cursor: 'pointer',
                              width: '128px',
                              height: '122px',
                              background: `url(${certHexagonImage}) no-repeat center`,
                            }}
                          >
                            {item.title[lang]}
                          </Box>
                        )
                      }
                      <Box
                        sx={sxMobileItem}
                      >
                        { (width <= 600 && index !==0 ) && <Box sx={{ fontWeight: 'bold', paddingBottom: '7px', fontSize: '14px' }}>{item.title[lang]}</Box> }
                        <Box>{item.description[lang]}</Box>
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            )
          }
          
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', }}>
            <ContentButton
              formTitle=''
              formType='content'
              formName={settings.formCert}
              formID=''
              openButton={constants.certContactUs[lang]}
              openButtonStyle={sxButton}
              submitButton={constants.send[lang]}
              submitButtonStyle=''
              setModalOpen={setModalOpen}
              setModalTitle={setModalTitle}
              setModalContent={setModalContent}
            />
            <ModalWindow
              modalOpen={modalOpen}
              modalTitle={modalTitle}
              modalContent={modalContent}
              modalButtons={[]}
              modalWidth='lg'
              onClose={handleCloseModal}
            />
          </Box>
        </RegularPageContentR5Box>
      </Container>
    </RegularPageFluidBox>
  )
}

export default Cert
import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContent0Box } from '../../themes/boxLayout'
import { newsItems } from '../../data/news'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiNews } from '../../helpers/urls'
import { sortByStartDate } from '../../helpers/array'
import useWindowDimensions from '../../helpers/dimensions'
import NewsItem from './NewsItem'
import NewsPagination from './NewsPagination'
import NewsFull from './NewsFull'
import ModalWindow from '../layout/ModalWindow'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'

const News = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [news, setNews] = useState([])
  const [newsFiltered, setNewsFiltered] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [pages, setPages] = useState(1)
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  const hanldeOpenModal = (newsObject) => {
    setModalOpen(true)
    setModalTitle(newsObject.date)
    setModalContent(<NewsFull {...newsObject} />)
  }

  const handlePageClick = (page) => {
    setCurrentPage(page)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const fetchNews = async () => {
      const response = await request(settings.domain + settings.api + apiNews.getItems, 'GET')
      
      if (response && response.length > 0) {
        setNews(sortByStartDate(response))
      } else {
        setNews([])
      }
    }

    if (settings.mode === 'live') {
      fetchNews()
    } else {
      setNews(sortByStartDate(newsItems))
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    setPages(Math.ceil(news.length/settings.newsCount))

    let newsArray = news.filter(item => item.is_visible)

    setNewsFiltered(newsArray.filter((item, index) => (index >= (currentPage - 1) * settings.newsCount && index < currentPage * settings.newsCount)))

    if (news.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [news, currentPage])
  
  return (
    <RegularPageFluidBox>
      <BackdropElement open={backdropOpen} />
      <ErrorMessage open={errorMessageOpen} />
      <Container maxWidth='xl' sx={sxContainer}> 
        <Grid container>
          <Grid xs={12} lg={5}>
            <RegularPageTitleBox>{constants.news[lang]}</RegularPageTitleBox>
          </Grid>
        </Grid>
        <RegularPageContent0Box>
          {
            newsFiltered.map((item, index) => (
              <NewsItem key={index} {...item} handleClick={hanldeOpenModal} />
            ))
          }
          {
            pages > 1 && <NewsPagination currentPage={currentPage} pages={pages} handleClick={handlePageClick} />
          }
          <ModalWindow
            modalOpen={modalOpen}
            modalTitle={modalTitle}
            modalContent={modalContent}
            modalButtons=''
            modalWidth='lg'
            onClose={handleCloseModal}
          />
        </RegularPageContent0Box>
      </Container>
    </RegularPageFluidBox>
  )
}

export default News
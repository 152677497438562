import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContentR5Box } from '../../themes/boxLayout'
import { internetPage, internetServiceCategory, internetService } from '../../data/internet'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiInternet } from '../../helpers/urls'
import { sortByPriority } from '../../helpers/array'
import useWindowDimensions from '../../helpers/dimensions'
import InternetPage from './InternetPage'
import InternetServices from './InternetServices'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'

const Internet = () => {
  const { width } = useWindowDimensions()
  const theme = useTheme()

  const lang = useSelector(state => state.lang)

  const [description, setDescription] = useState([])
  const [cats, setCats] = useState([])
  const [services, setServices] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  const getDescription = (a) => {
    const object = a.find(item => item.var_name === 'description')
    return object ? object.data : []
  }

  useEffect(() => {
    const fetchDescription = async () => {
      const response = await request(settings.domain + settings.api + apiInternet.getPage, 'GET')

      if (response && response.length > 0) {
        setDescription(getDescription(response))
      } else {
        setDescription([])
      }
    }

    const fetchCats = async () => {
      const response = await request(settings.domain + settings.api + apiInternet.getCat, 'GET')

      if (response && response.length > 0) {
        setCats(sortByPriority(response))
      } else {
        setCats([])
      }
    }

    const fetchServices = async () => {
      const response = await request(settings.domain + settings.api + apiInternet.getServices, 'GET')

      if (response && response.length > 0) {
        setServices(response)
      } else {
        setServices([])
      }
    }

    if (settings.mode === 'live') {
      fetchDescription()
      fetchCats()
      fetchServices()
    } else {
      setDescription(getDescription(internetPage))
      setCats(internetServiceCategory)
      setServices(internetService)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    if (description.length > 0 && cats.length > 0 && services.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [description, cats, services])
  
  return (
    <RegularPageFluidBox>
      <BackdropElement open={backdropOpen} />
      <ErrorMessage open={errorMessageOpen} />
      <Container maxWidth='xl' sx={sxContainer}> 
        <Grid container>
          <Grid xs={12} lg={5}>
            <RegularPageTitleBox>
              <Box component='span' sx={{ paddingTop: lang === 'ka' ? '5px' : '0px' }}>{constants.internet[lang]}</Box>
              <Box component='span' sx={{ paddingTop: lang === 'ka' ? '5px' : '0px' }}>&nbsp;/&nbsp;</Box>
              <Box component='span' sx={{ fontFamily: `${theme.typography.roboto.fontFamily} !important` }}>{constants.geant[lang]}</Box>
            </RegularPageTitleBox>
          </Grid>
        </Grid>
        <RegularPageContentR5Box>
          <InternetPage description={description} />
          <InternetServices cats={cats} services={services} />
        </RegularPageContentR5Box>
      </Container>
    </RegularPageFluidBox>
  )
}

export default Internet
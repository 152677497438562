import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import CircularProgress from '@mui/material/CircularProgress'
import { RegularPageFluidBox, RegularPageTitleBox, OnlyPaddingBox } from '../../themes/boxLayout'
import { DomainsSearchContainerBox, DomainsBottomBox } from '../../themes/boxDomains'
import { DomainCheckButton, DomainsRulesButton, DomainsProfileButton, DomainsRegisterModeButton, DomainsTransferModeButton } from '../../themes/button'
import { domainItems, specialDomainItems, domainRules, domainPrices } from '../../data/domains'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import { BiTransfer } from 'react-icons/bi'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiDomains } from '../../helpers/urls'
import { validateDomainName } from '../../helpers/string'
import { sortByPriority } from '../../helpers/array'
import useWindowDimensions from '../../helpers/dimensions'
import DomainSearchText from '../form/DomainSearchText'
import DomainEppCodeText from '../form/DomainEppCodeText'
import DomainSearchSelect from '../form/DomainSearchSelect'
import DomainPrices from './DomainPrices'
import DomainSearchResult from './DomainSearchResult'
import ModalWindow from '../layout/ModalWindow'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'

const Domains = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [domains, setDomains] = useState([])
  const [specialDomains, setSpecialDomains] = useState([])
  const [rules, setRules] = useState([])
  const [prices, setPrices] = useState([])
  const [domainName, setDomainName] = useState('')
  const [domainExt, setDomainExt] = useState('')
  const [domainExtList, setDomainExtList] = useState([])
  const [domainWhois, setDomainWhois] = useState('')
  const [domainSearchMode, setDomainSearchMode] = useState('register')
  const [eppCode, setEppCode] = useState('')
  const [loader, setLoader] = useState(false)
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')

  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  const getDomainExtList = (domains, specialDomains, searchMode) => {
    let domainExtListArray = []

    domains.forEach(item => {
      if (item.type === 'en') domainExtListArray.push(item.domain_name)
    })

    specialDomains.forEach(item => {
      domainExtListArray.push(item.spec_domain_name)
    })

    domains.forEach(item => {
      if (item.type === 'ka' && searchMode === 'register') domainExtListArray.push(item.domain_name)
    })

    setDomainExtList(domainExtListArray)
    if (domainExtListArray.length > 0) setDomainExt(domainExtListArray[0])
  }

  const handleNameChange = (e) => {
    const language = domainExt === '.გე' ? 'ka' : 'en'
    const valid = validateDomainName(e.target.value, language)
    if (valid) setDomainName(e.target.value)
  }

  const handleExtChange = (e) => {
    if (domainExt === '.გე' || e.target.value === '.გე') setDomainName('')
    setDomainExt(e.target.value)
  }

  const handleEppCodeChange = (e) => {
    setEppCode(e.target.value)
  }

  const handleOpenPricesModal = () => {
    setModalOpen(true)
    setModalTitle(constants.tariffs[lang])
    setModalContent(<DomainPrices prices={prices} />)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleSubmit = async () => {
    if (domainName) {
      setLoader(true)
      setDomainWhois('')

      const domainFullName = domainName + domainExt
      const postURL = settings.fetchDomains + '?action=whois&domain=' + domainFullName

      const element = document.getElementById('search_result')
      if (element) element.scrollIntoView({ behavior: 'smooth' })

      const response = await request(postURL)
      
      if (response.code === 100) {
        setDomainWhois(response.data)
        setLoader(false)
      } else {
        setLoader(false)
      }
    }
  }

  const handleTransfer = () => {
    if (domainName && domainExt && eppCode) {
      const langName = lang === 'en' ? 'eng' : 'geo'
      const domainNameArray = domainName.split('.')
      const realDomainName = domainNameArray[0] + domainExt
      const url = `https://domain.grena.ge/index.php?a=transfer&lang=${langName}&domain=${realDomainName}&epp_code=${eppCode}`
      window.open(url, '_blank')
    }
  }

  const handleClickRules = (e) => {
    const domainObject = domainExt === '.გე' ? domains.find(item => item.domain_name === '.გე') : domains.find(item => item.domain_name === '.ge')
    const domainID = domainObject ? domainObject.id : ''
    const domainRulesObject = rules.find(item => item.domain === domainID)
    const domainRulesFile = domainRulesObject ? settings.s3URL + settings.s3Bucket + domainRulesObject.file_url[lang] : ''

    if (domainRulesFile) {
      if (e.ctrlKey || e.button === 1) {
        window.open(domainRulesFile, '_blank')
      } else if (e.type === 'click') {
        window.open(domainRulesFile, '_blank')
      }
    }
  }

  const handleClickProfile = (e) => {
    let profileUrl = ''
    const langName = lang === 'en' ? 'eng' : 'geo'

    if (domainExt === '.გე') {
      profileUrl = `https://xn--oodcmkgd.xn--loddg6as.xn--node/index.php?a=login`
    } else {
      profileUrl = `https://domain.grena.ge/index.php?a=login&lang=${langName}`
    }

    if (e.ctrlKey || e.button === 1) {
      window.open(profileUrl, '_blank')
    } else if (e.type === 'click') {
      window.open(profileUrl, '_blank')
    }
  }

  useEffect(() => {
    const fetchDomains = async () => {
      const response = await request(settings.domain + settings.api + apiDomains.getDomains, 'GET')

      if (response && response.length > 0) {
        setDomains(response)
      } else {
        setDomains([])
      }
    }

    const fetchSpecialDomains = async () => {
      const response = await request(settings.domain + settings.api + apiDomains.getSpecialDomains, 'GET')

      if (response && response.length > 0) {
        setSpecialDomains(response)
      } else {
        setSpecialDomains([])
      }
    }

    const fetchRules = async () => {
      const response = await request(settings.domain + settings.api + apiDomains.getRules, 'GET')

      if (response && response.length > 0) {
        setRules(response)
      } else {
        setRules([])
      }
    }

    const fetchPrices = async () => {
      const response = await request(settings.domain + settings.api + apiDomains.getPriceList, 'GET')

      if (response && response.length > 0) {
        setPrices(sortByPriority(response))
      } else {
        setPrices([])
      }
    }

    if (settings.mode === 'live') {
      fetchDomains()
      fetchSpecialDomains()
      fetchRules()
      fetchPrices()
    } else {
      setDomains(domainItems)
      setSpecialDomains(specialDomainItems)
      setRules(domainRules)
      setPrices(domainPrices)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    getDomainExtList(domains, specialDomains, domainSearchMode)

    if (domains.length > 0 && specialDomains.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [domains, specialDomains, domainSearchMode])
  
  return (
    <RegularPageFluidBox>
      <BackdropElement open={backdropOpen} />
      <ErrorMessage open={errorMessageOpen} />
      <Container maxWidth='xl' sx={sxContainer}>
        <Grid container>
          <Grid xs={12} lg={5}>
            <RegularPageTitleBox>{constants.domains[lang]}</RegularPageTitleBox>
          </Grid>
        </Grid>
      </Container>
      
      <DomainsSearchContainerBox>
        <Container maxWidth='xl'>
          <OnlyPaddingBox>
            <Box sx={{ display: 'flex', flexDirection: width > settings.padBreakpoint ? 'row' : 'column', alignItems: 'center', justifyContent: 'center' }}>
              <DomainsRegisterModeButton variant='contained' disableRipple={true} onClick={() => setDomainSearchMode('register')}>{constants.register[lang]}</DomainsRegisterModeButton>
              <DomainsTransferModeButton variant='contained' disableRipple={true} onClick={() => setDomainSearchMode('transfer')}>{constants.transfer[lang]}</DomainsTransferModeButton>
            </Box>

            {
              domainSearchMode === 'register' ? (
                <Box component='form' autoComplete='off' noValidate>
                  <Grid container>
                    <Grid xs={9} md={9}>
                      <DomainSearchText value={domainName} placeholder={`${constants.enterDomain[lang]}...`} handleChange={handleNameChange} handleSubmit={handleSubmit} />
                    </Grid>
                    <Grid xs={3} md={2}>
                      <DomainSearchSelect value={domainExt} list={domainExtList} placeholder='' handleChange={handleExtChange} />
                    </Grid>
                    <Grid xs={12} md={1}>
                      <DomainCheckButton variant='contained' disableRipple onClick={handleSubmit}>
                        <FaMagnifyingGlass size={24} />
                      </DomainCheckButton>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box component='form' autoComplete='off' noValidate>
                  <Grid container>
                    <Grid xs={9} md={5}>
                      <DomainSearchText value={domainName} placeholder={`${constants.enterDomain[lang]}...`} handleChange={handleNameChange} handleSubmit={handleTransfer} />
                    </Grid>
                    <Grid xs={3} md={2}>
                      <DomainSearchSelect value={domainExt} list={domainExtList} placeholder='' handleChange={handleExtChange} />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <DomainEppCodeText value={eppCode} placeholder={constants.eppCode[lang]} handleChange={handleEppCodeChange} handleSubmit={handleTransfer} />
                    </Grid>
                    <Grid xs={12} md={1}>
                      <DomainCheckButton variant='contained' disableRipple onClick={handleTransfer}>
                        <BiTransfer size={24} />
                      </DomainCheckButton>
                    </Grid>
                  </Grid>
                </Box>
              )
            }            
          </OnlyPaddingBox>
        </Container>
      </DomainsSearchContainerBox>

      <Container maxWidth='xl'>
        <DomainsBottomBox>
          <Box id='search_result'></Box>
          
          <Box sx={{ display: loader ? 'flex' : 'none', justifyContent: 'center', marginBottom: '30px' }}>
            <CircularProgress sx={{ color: '#074D78' }} />
          </Box>
          
          { domainWhois && <DomainSearchResult whois={domainWhois} domains={domains} specialDomains={specialDomains} prices={prices} />}
          
          <Box sx={{ display: 'flex', flexDirection: width > settings.desktopBreakpoint ? 'row' : 'column', justifyContent: 'flex-start' }}>
            <DomainsRulesButton variant='contained' disableRipple onClick={handleClickRules} onMouseDown={handleClickRules}>{constants.rules[lang]}</DomainsRulesButton>
            <DomainsProfileButton variant='contained' disableRipple onClick={handleClickProfile} onMouseDown={handleClickProfile}>{constants.domainLogInToProfile[lang]}</DomainsProfileButton>
            <DomainsProfileButton variant='contained' disableRipple onClick={handleOpenPricesModal}>{constants.tariffs[lang]}</DomainsProfileButton>
          </Box>         
        </DomainsBottomBox>
      </Container>

      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalButtons={[]}
        modalWidth='lg'
        onClose={handleCloseModal}
      />

    </RegularPageFluidBox>
  )
}

export default Domains
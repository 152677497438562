import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContentL5R10Box } from '../../themes/boxLayout'
import { projectItems, projectCategories } from '../../data/projects'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiProjects } from '../../helpers/urls'
import { sortByPriority } from '../../helpers/array'
import useWindowDimensions from '../../helpers/dimensions'
import ProjectsCategory from './ProjectsCategory'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'

const Projects = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [projects, setProjects] = useState([])
  const [cats, setCats] = useState([])
  const [expanded, setExpanded] = useState(-1)
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  const handleExpand = (index) => {
    setExpanded(index === expanded ? '-1' : index)
  }

  useEffect(() => {
    const fetchProjects = async () => {
      const response = await request(settings.domain + settings.api + apiProjects.getItems, 'GET')

      if (response && response.length > 0) {
        setProjects(response)
      } else {
        setProjects([])
      }
    }

    const fetchCats = async () => {
      const response = await request(settings.domain + settings.api + apiProjects.getCat, 'GET')

      if (response && response.length > 0) {
        setCats(sortByPriority(response))
      } else {
        setCats([])
      }
    }

    if (settings.mode === 'live') {
      fetchProjects()
      fetchCats()
    } else {
      setProjects(projectItems)
      setCats(sortByPriority(projectCategories))
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    if (cats.length > 0 && projects.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [cats, projects])

  return (
    <RegularPageFluidBox>
      <BackdropElement open={backdropOpen} />
      <ErrorMessage open={errorMessageOpen} />
      <Container maxWidth='xl' sx={sxContainer}> 
        <Grid container>
          <Grid xs={12} lg={5}>
            <RegularPageTitleBox>{constants.projects[lang]}</RegularPageTitleBox>
          </Grid>
        </Grid>
        <RegularPageContentL5R10Box>
          {
            cats.map((item, index) => (
              <ProjectsCategory key={index} expanded={expanded} index={index} handleExpand={handleExpand} projects={projects} {...item} />
            ))
          }
        </RegularPageContentL5R10Box>
      </Container>
    </RegularPageFluidBox>
  )
}

export default Projects
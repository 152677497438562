import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContentR5Box } from '../../themes/boxLayout'
import { AcademyLeftBox, AcademyRightBox, AcademyCoursesLabelBox } from '../../themes/boxEducation'
import { academyItems, coursesItems, courseLanguagesItems, courseDurationUnitsItems, courseRegistrationsItems } from '../../data/education'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiEducation } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'
import AcademyCoursesItem from './AcademyCoursesItem'
import ModalWindow from '../layout/ModalWindow'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'

const Academy = () => {
  const theme = useTheme()
  const params = useParams()
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const academyInitial = {
    name: '',
    logo: '',
    illustration: '',
    description: [],
  }

  const [academy, setAcademy] = useState(academyInitial)
  const [courses, setCourses] = useState([])
  const [courseLanguages, setCourseLanguages] = useState([])
  const [courseDurationUnits, setCourseDurationUnits] = useState([])
  const [courseRegistrations, setCourseRegistrations] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [courseClickable, setCourseClickable] = useState(true)

  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  const sxLogo = width > settings.desktopBreakpoint ? { maxWidth: '30%', float: 'left', margin: '0px 20px 10px 0px' } : { maxWidth: '40%', float: 'left', margin: '0px 20px 10px 0px' }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const setAcademyInfo = (a) => {
      const object = a.find(item => item.id === params.id)
      const name = object ? object.name[lang] : ''
      const logo = object ? settings.s3URL + settings.s3Bucket + object.logo_url : ''
      const illustration = object ? settings.s3URL + settings.s3Bucket + object.illustration_url : ''
      const description = object ? object.about_texts : []
      setAcademy({ name, logo, illustration, description })
    }

    const fetchAcademyInfo = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getAcademies, 'GET')
      
      if (response && response.length > 0) {
        setAcademyInfo(response)
      } else {
        setAcademyInfo([])
      }
    }

    const fetchCourses = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourses, 'GET')

      if (response && response.length > 0) {
        setCourses(response.filter(item => (item.academy === params.id && item.is_active)))
      } else {
        setCourses([])
      }
    }

    const fetchCourseLanguages = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourseLanguages, 'GET')

      if (response && response.length > 0) {
        setCourseLanguages(response)
      } else {
        setCourseLanguages([])
      }
    }

    const fetchCourseDurationUnits = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourseDurationUnits, 'GET')

      if (response && response.length > 0) {
        setCourseDurationUnits(response)
      } else {
        setCourseDurationUnits([])
      }
    }

    const fetchCourseRegistrations = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourseRegistrations, 'GET')

      if (response && response.length > 0) {
        setCourseRegistrations(response)
      } else {
        setCourseRegistrations([])
      }
    }
    
    if (settings.mode === 'live') {
      fetchAcademyInfo()
      fetchCourses()
      fetchCourseLanguages()
      fetchCourseDurationUnits()
      fetchCourseRegistrations()
    } else {
      setAcademyInfo(academyItems)
      setCourses(coursesItems.filter(item => item.academy === params.id))
      setCourseLanguages(courseLanguagesItems)
      setCourseDurationUnits(courseDurationUnitsItems)
      setCourseRegistrations(courseRegistrationsItems)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [params.id, lang])

  useEffect(() => {
    if (modalOpen) {
      setCourseClickable(false)
    } else {
      setCourseClickable(true)
    }
  }, [modalOpen])
  
  useEffect(() => {
    if (academy.name && courses.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [academy, courses])
  
  return (
    <RegularPageFluidBox>
      <BackdropElement open={backdropOpen} />
      <ErrorMessage open={errorMessageOpen} />
      <Container maxWidth='xl' sx={sxContainer}> 
        <Grid container>
          <Grid xs={12} lg={5}>
            <RegularPageTitleBox>{academy.name}</RegularPageTitleBox>
          </Grid>
        </Grid>
        <RegularPageContentR5Box>
          <Grid container spacing={4}>
            <Grid lg={7}>
              <AcademyLeftBox>
                { academy.logo && <img src={academy.logo} alt='' style={sxLogo} /> }
                {
                  academy.description.map((item, index) => (
                    <Box key={index} sx={{ paddingBottom: (index === academy.description.length - 1 ? '0px' : '24px') }}>{item[lang]}</Box>
                  ))
                }
                <Box sx={{ clear: 'both' }}></Box>
              </AcademyLeftBox>
            </Grid>
            {
              width > settings.desktopBreakpoint && (
                <Grid lg={5}>
                  <AcademyRightBox sx={{ height: '380px' }}>
                    {
                      academy.illustration && (
                        <Box sx={{
                          width: '380px',
                          height: '380px',
                          borderRadius: '190px',
                          background: `url(${academy.illustration}) no-repeat center / cover`,
                          border: `${theme.palette.primary.main} 1px solid`,
                        }}></Box>
                      )
                    }
                  </AcademyRightBox>
                </Grid>
              )
            }
          </Grid>
          <AcademyCoursesLabelBox>{constants.courses[lang]}</AcademyCoursesLabelBox>
          {
            courses.map((item, index) => (
              <AcademyCoursesItem
                key={index}
                {...item}
                courseLanguages={courseLanguages}
                courseDurationUnits={courseDurationUnits}
                courseRegistrations={courseRegistrations}
                setModalOpen={setModalOpen}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setBackdropOpen={setBackdropOpen}
                courseClickable={courseClickable}
              />
            ))
          }
          <ModalWindow
            modalOpen={modalOpen}
            modalTitle={modalTitle}
            modalContent={modalContent}
            modalButtons={[]}
            modalWidth='lg'
            onClose={handleCloseModal}
          />
        </RegularPageContentR5Box>
      </Container>
    </RegularPageFluidBox>
  )
}

export default Academy
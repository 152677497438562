import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { FormSubmitButton } from '../../themes/button'
import FormHelperText from '@mui/material/FormHelperText'
import { sortByPriority } from '../../helpers/array'
import { isValidEmail, isValidPhone } from '../../helpers/string'
import { apiContentForms, apiEducation } from '../../helpers/urls'
import settings from '../../helpers/settings'
import request from '../../helpers/request'
import { constants } from '../../data/constants'
import { getFormattedDate } from '../../helpers/dates'
import ReCAPTCHA from 'react-google-recaptcha'
import useWindowDimensions from '../../helpers/dimensions'
import parse from 'html-react-parser'
import ContentFormItem from './ContentFormItem'

const ContentForm = ({
  formTitle,
  formType,
  formName,
  formFields,
  submitButton,
  setModalOpen,
  setModalTitle,
  setModalContent,
  setBackdropOpen
}) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const captchaRef = useRef('')
  
  const [formSubfields, setFormSubfields] = useState([])
  const [contentTypes, setContentTypes] = useState([])
  const [filled, setFilled] = useState([])
  const [errors, setErrors] = useState([])
  const [captchaValidated, setCaptchaValidated] = useState(false)
  const [captchaValidationError, setCaptchaValidationError] = useState(false)

  const formWidth = width > settings.desktopBreakpoint ? '600px' : ''
  
  const updateFilled = ({ field_id, priority, type, required, value }) => {
    let old = filled

    const oldObject = old.find(item => item.field_id === field_id)
    const tempArray = old.filter(item => item.field_id !== field_id)

    if (oldObject) {
      const newObject = { ...oldObject, value }
      setFilled(sortByPriority([...tempArray, newObject]))
    } else {
      tempArray.push({ field_id, value, priority, type, required  })
      setFilled(sortByPriority(tempArray))
    }
  }

  const updateContentTypes = ({ field_id, contentType }) => {
    let tempArray = contentTypes
    tempArray.push({ field_id, contentType })
    setContentTypes(tempArray)
  }

  const modifySubfields = (field_id, shownSubfields) => {
    const temp = []

    formSubfields.forEach(item => {
      if (item.field_id === field_id) {
        if (shownSubfields.includes(item.subfield_id)) {
          temp.push({ field_id: item.field_id, subfield_id: item.subfield_id, show: true })
        } else {
          temp.push({ field_id: item.field_id, subfield_id: item.subfield_id, show: false })
        }
      } else {
        temp.push(item)
      }
    })

    setFormSubfields(temp)
  }

  const googleCaptchaValidation = async (token) => {
    const response = await fetch(
      `/recaptcha/api/siteverify?secret=${settings.googleReCaptchaSecretKey}&response=${token}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', }
      }
    )
    
    if (!response.ok) {
      const resErrData = await response.json();
      console.log(resErrData)
      } else {
      const resData = await response.json();
      return resData.success
      }   
  }

  const sendRequest = async (filled) => {
    let postData = ''
    let action = ''
    let responseTitleOk = ''
    let responseTitleFail = ''
    let responseContentOk = ''
    let responseContentFail = ''

    if (formType === 'content') {
      postData = { form_name: formName, data: filled, }
      action = apiContentForms.getSubmitForm
      responseTitleOk = constants.subscribeOkTitle[lang]
      responseTitleFail = constants.subscribeFailedTitle[lang]
      responseContentOk = parse(constants.messageOK[lang])
      responseContentFail = constants.subscribeFailedText[lang]
    } else if (formType === 'academy_registration') {
      postData = { registration_id: formName, data: filled, }
      action = apiEducation.getStudentRegistrations
      responseTitleOk = constants.registrationOkTitle[lang]
      responseTitleFail = constants.registrationFailedTitle[lang]
      responseContentOk = constants.registrationOkText[lang]
      responseContentFail = constants.registrationFailedText[lang]
    } else if (formType === 'academy_subscription') {
      postData = { course_id: formName, email: filled[0].value, }
      action = apiEducation.getCourseSubscriptions
      responseTitleOk = constants.subscribeOkTitle[lang]
      responseTitleFail = constants.subscribeFailedTitle[lang]
      responseContentOk = constants.subscribeOkText[lang]
      responseContentFail = constants.subscribeFailedText[lang]
    }
    
    setModalOpen(false)
    setBackdropOpen(true)

    const response = await request(settings.domain + settings.api + action, 'POST', postData)
    
    if (response && (response.data || response.id)) {
      setTimeout(() => {
        setModalOpen(true)
        setBackdropOpen(false)
        setModalTitle(responseTitleOk)
        setModalContent(responseContentOk)
      }, 1000)
    } else {
      setTimeout(() => {
        setModalOpen(true)
        setBackdropOpen(false)
        setModalTitle(responseTitleFail)
        setModalContent(responseContentFail)
      }, 1000)
    }
  }

  const handleSubmit = async () => {
    setErrors([])
    let errorsArray = []
    
    filled.forEach(item => {
      const isEmailObject = contentTypes.find(item2 => (item2.field_id === item.field_id && item2.contentType === 'email'))
      const isPhoneObject = contentTypes.find(item2 => (item2.field_id === item.field_id && item2.contentType === 'phone'))
      const isIDNumberObject = contentTypes.find(item2 => (item2.field_id === item.field_id && item2.contentType === 'id_number'))

      if (isEmailObject && !isValidEmail(item.value)) errorsArray.push(item.field_id)
      if (isPhoneObject && !isValidPhone(item.value)) errorsArray.push(item.field_id)
      if (isIDNumberObject && item.value.length !== 11) errorsArray.push(item.field_id)

      if (item.required && item.type !== 'select' && !item.value) errorsArray.push(item.field_id)
      if (item.required && item.type === 'select' && (item.value === null || item.value.length === 0)) errorsArray.push(item.field_id)
    })

    formSubfields.forEach(item => {
      if (item.show) {
        const object = filled.find(item2 => item2.field_id === item.subfield_id)
        if (!object || !object.value) errorsArray.push(item.subfield_id)
      }
    })
    
    setErrors(errorsArray)    
    
    if (errorsArray.length === 0 && filled.length > 0) {
      const reCaptchaResponse = captchaValidated ? true : await googleCaptchaValidation(captchaRef.current.getValue())
      setCaptchaValidated(reCaptchaResponse ? true : false)

      if (reCaptchaResponse) {
        sendRequest(filled)
      } else {
        setCaptchaValidationError(true)
      }
    }
  }
  
  useEffect(() => {
    let filledArray = []
    let subfieldsArray = []

    formFields.forEach(item => {
      let object = {
        field_id: item.field_id,
        value: null,
        priority: item.priority,
        type: item.type,
        required: item.required,
      }

      if (formType === 'content' && item.options.content_type && item.options.content_type === 'hidden') {
        object.value = formTitle
      } else if (formType === 'academy_registration' && item.options.content_type && item.options.content_type === 'hidden') {
        object.value = getFormattedDate()
      }

      filledArray.push(object)  

      if (item.has_subfield && item.required) {
        item.subfields.forEach(item2 => {
          subfieldsArray.push({ field_id: item.field_id, subfield_id: item2.subfield_id, show: false })
        })
      }
    })

    setFilled(filledArray)
    setFormSubfields(subfieldsArray)
  }, [formFields, formTitle, formType])
  
  return (
    <Box>
      {
        formFields.map((item, index) => (
          <ContentFormItem
            key={index}
            {...item}
            updateFilled={updateFilled}
            modifySubfields={modifySubfields}
            updateContentTypes={updateContentTypes}
            errors={errors}
            formWidth={formWidth}
          />
        ))
      }
      <Box sx={{ marginBottom: '20px' }}>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={settings.googleReCaptchaSiteKey}
          hl={lang}
          size={ width > settings.padBreakpoint ? 'normal' : 'compact' }
          style={{ width: '100%' }}
        />
        { captchaValidationError && <FormHelperText style={{ color: 'red' }}>{constants.reCaptchaError[lang]}</FormHelperText> }
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FormSubmitButton variant='contained' disableRipple onClick={handleSubmit}>{submitButton}</FormSubmitButton>
      </Box>
    </Box>
  )
}

export default ContentForm
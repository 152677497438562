import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContentR5Box } from '../../themes/boxLayout'
import { eduroamPage, eduroamMemebers, eduroamDocs, eduroamFAQ } from '../../data/eduroam'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiEduroam } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'
import EduroamPage from './EduroamPage'
import EduroamMembers from './EduroamMembers'
import EduroamFaq from './EduroamFaq'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'

const Eduroam = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [page, setPage] = useState([])
  const [members, setMembers] = useState([])
  const [docs, setDocs] = useState([])
  const [faq, setFaq] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  useEffect(() => {
    const fetchPage = async () => {
      const response = await request(settings.domain + settings.api + apiEduroam.getPage, 'GET')

      if (response && response.length > 0) {
        setPage(response)
      } else {
        setPage([])
      }
    }

    const fetchMembers = async () => {
      const response = await request(settings.domain + settings.api + apiEduroam.getMembers, 'GET')

      if (response && response.length > 0) {
        setMembers(response)
      } else {
        setMembers([])
      }
    }

    const fetchDocs = async () => {
      const response = await request(settings.domain + settings.api + apiEduroam.getDocs, 'GET')

      if (response && response.length > 0) {
        setDocs(response)
      } else {
        setDocs([])
      }
    }

    const fetchFaq = async () => {
      const response = await request(settings.domain + settings.api + apiEduroam.getFaq, 'GET')

      if (response && response.length > 0) {
        setFaq(response)
      } else {
        setFaq([])
      }
    }

    if (settings.mode === 'live') {
      fetchPage()
      fetchMembers()
      fetchDocs()
      fetchFaq()
    } else {
      setPage(eduroamPage)
      setMembers(eduroamMemebers)
      setDocs(eduroamDocs)
      setFaq(eduroamFAQ)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    if (page.length > 0 && members.length > 0 && docs.length > 0 && faq.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [page, members, docs, faq])

  return (
    <RegularPageFluidBox>
      <BackdropElement open={backdropOpen} />
      <ErrorMessage open={errorMessageOpen} />
      <Container maxWidth='xl' sx={sxContainer}> 
        <Grid container>
          <Grid xs={12} lg={5}>
            <RegularPageTitleBox>{constants.eduroam[lang]}</RegularPageTitleBox>
          </Grid>
        </Grid>
        <RegularPageContentR5Box>
          <EduroamPage page={page} members={members} docs={docs} />
          <EduroamMembers members={members} />
          <EduroamFaq faq={faq} />
        </RegularPageContentR5Box>
      </Container>
    </RegularPageFluidBox>
  )
}

export default Eduroam
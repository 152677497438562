import React, { useState, useEffect, useRef } from 'react'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiServices, apiProjects, apiPartners } from '../../helpers/urls'
import { servicesItems } from '../../data/services'
import { projectsOngoingItems } from '../../data/projects'
import { partnersItems } from '../../data/partners'
import { sortByPriority } from '../../helpers/array'
import useWindowDimensions from '../../helpers/dimensions'
import HomeServices from './HomeServices'
import HomeProjects from './HomeProjects'
import HomePartners from './HomePartners'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'

const Home = () => {
  const { width } = useWindowDimensions()
  
  const [services, setServices] = useState(sortByPriority(servicesItems))
  const [projects, setProjects] = useState([])
  const [partners, setPartners] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const [logosCount, setLogosCount] = useState(9)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  useEffect(() => {
    if (width > 599) {
      setLogosCount(9)
    } else {
      setLogosCount(6)
    }
  }, [width])

  useEffect(() => {
    const getGroupedPartners = (partners) => {
      let groupedPartners = []
      let i = 0
  
      partners.forEach((item, index) => {
        if (!groupedPartners[i]) groupedPartners[i] = []
        groupedPartners[i].push(item)
        if ((index + 1) % logosCount === 0) i++      
      })
  
      return groupedPartners
    }

    const fetchServices = async () => {
      const response = await request(settings.domain + settings.api + apiServices.getItems, 'GET')

      if (response && response.length > 0) {
        setServices(sortByPriority(response))
      } else {
        setServices(sortByPriority(servicesItems))
      }
    }

    const fetchProjects = async () => {
      const response = await request(settings.domain + settings.api + apiProjects.getOngoing, 'GET')

      if (response && response.length > 0) {
        setProjects(response)
      } else {
        setProjects([])
      }
    }

    const fetchPartners = async () => {
      const response = await request(settings.domain + settings.api + apiPartners.getItems, 'GET')

      if (response && response.length > 0) {
        setPartners(getGroupedPartners(response))
      } else {
        setPartners([])
      }
    }

    if (settings.mode === 'live') {
      fetchServices()
      fetchProjects()
      fetchPartners()
    } else {
      setProjects(projectsOngoingItems)
      setPartners(getGroupedPartners(partnersItems))
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [logosCount])

  useEffect(() => {
    if (services.length > 0 && projects.length > 0 && partners.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [services, projects, partners])

  return (
    <>
      <BackdropElement open={backdropOpen} />
      <ErrorMessage open={errorMessageOpen} />
      <HomeServices services={services} />
      <HomeProjects projects={projects} />
      <HomePartners partners={partners} />
    </>
  )
}

export default Home
import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox } from '../../themes/boxLayout'
import { coursesItems, courseLanguagesItems, courseDurationUnitsItems, courseRegistrationsItems } from '../../data/education'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiEducation } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'
import CourseSummary from './CourseSummary'
import CourseInfo from './CourseInfo'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'

const Course = () => {
  const params = useParams()
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const courseInitial = {
    name: '',
    price: '',    
    duration: '',
    frequency: '',
    language: '',
    materialsLanguage: '',
    lessonDuration: '',
    startTime: [],
    paymentDescription: [],
    studyProcess: [],
    syllabus: '',
    registrationInstructions: [],
    registrationOpen: 0,
    registrationID: '',
    registrationFormID: '',
  }

  const [course, setCourse] = useState(courseInitial)
  const [courseLanguages, setCourseLanguages] = useState([])
  const [courseDurationUnits, setCourseDurationUnits] = useState([])
  const [courseRegistrations, setCourseRegistrations] = useState([])
  const [registrationOpen, setRegistrationOpen] = useState(true)
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  useEffect(() => {
    const fetchCourseRegistrations = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourseRegistrations, 'GET')

      if (response && response.length > 0) {
        setCourseRegistrations(response)
      } else {
        setCourseRegistrations([])
      }
    }

    const fetchCourseLanguages = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourseLanguages, 'GET')

      if (response && response.length > 0) {
        setCourseLanguages(response)
      } else {
        setCourseLanguages([])
      }
    }

    const fetchCourseDurationUnits = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourseDurationUnits, 'GET')

      if (response && response.length > 0) {
        setCourseDurationUnits(response)
      } else {
        setCourseDurationUnits([])
      }
    }

    if (settings.mode === 'live') {
      fetchCourseRegistrations()
      fetchCourseLanguages()
      fetchCourseDurationUnits()
    } else {
      setCourseRegistrations(courseRegistrationsItems)
      setCourseLanguages(courseLanguagesItems)
      setCourseDurationUnits(courseDurationUnitsItems)
    }
  }, [params.id, lang])
  
  useEffect(() => {
    const setCourseInfo = (a) => {
      const object = a.find(item => item.id === params.id)
  
      const registrationOpenObject = courseRegistrations.find(item => (item.course === params.id && item.is_opened))
      const registrationID = registrationOpenObject ? registrationOpenObject.id : ''
      const registrationFormID = registrationOpenObject ? registrationOpenObject.form_id : ''
  
      const name = object ? object.name[lang] : ''
      
      const price = object ? object.price : ''
  
      const courseDurationUnit = object ? object.duration_unit : ''
      const durationUnitObject = courseDurationUnits.find(item => item.id === courseDurationUnit)
      const durationUnit = durationUnitObject ? durationUnitObject.title[lang] : ''
      const duration = object ? (object.duration_range.join('-') + ' ' + durationUnit) : ''
  
      const frequency = object ? object.frequency : ''
  
      const languageObject = courseLanguages.find(item => item.id === object.language)
      const language = languageObject ? languageObject.title[lang] : ''
  
      const materialsLanguageObject = courseLanguages.find(item => item.id === object.materials_language)
      const materialsLanguage = materialsLanguageObject ? materialsLanguageObject.title[lang] : ''
  
      const lessonDuration = object ? object.lesson_duration.join('-') : ''
  
      const startTime = object ? object.start_time : []
  
      const paymentDescription = object ? object.payment_description : []
  
      const studyProcess = object ? object.study_process_description : []
  
      const syllabus = object ? settings.s3URL + settings.s3Bucket + object.syllabus_url : ''
  
      const registrationInstructions = object ? object.registration_instructions : []
      
      setCourse({
        name,
        price,    
        duration,
        frequency,
        language,
        materialsLanguage,
        lessonDuration,
        startTime,
        paymentDescription,
        studyProcess,
        syllabus,
        registrationInstructions,
        registrationID,
        registrationFormID
      })
    }

    const fetchCourseInfo = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourses, 'GET')

      if (response && response.length > 0) {
        setCourseInfo(response)

        if (courseRegistrations.length > 0) {
          const registrationOpenObject = courseRegistrations.find(item => (item.course === params.id && item.is_opened))
          const registrationOpenValue = registrationOpenObject ? true : false
          setRegistrationOpen(registrationOpenValue)
        }
      } else {
        setCourseInfo([])
      }
    }
    
    if (settings.mode === 'live') {
      fetchCourseInfo()
    } else {
      setCourseInfo(coursesItems)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [params.id, courseRegistrations, courseLanguages, courseDurationUnits, lang])

  useEffect(() => {
    if (course.name) {
      setBackdropOpen(false)
      setErrorMessageOpen(false) 
    }
  }, [course])
  
  return (
    <RegularPageFluidBox>
      <BackdropElement open={backdropOpen} />
      <ErrorMessage open={errorMessageOpen} />
      <Container maxWidth='xl' sx={sxContainer}>
        <Grid container>
          <Grid xs={12} lg={5}>
            <RegularPageTitleBox>{course.name}</RegularPageTitleBox>
          </Grid>
        </Grid>
      </Container>
      <CourseSummary
        id={params.id}
        name={course.name}
        duration={course.duration}
        frequency={course.frequency}
        language={course.language}
        materialsLanguage={course.materialsLanguage}
        price={course.price}
        registrationOpen={registrationOpen}
        registrationID={course.registrationID}
        registrationFormID={course.registrationFormID}
        setBackdropOpen={setBackdropOpen}
      />
      <CourseInfo
        duration={course.duration}
        frequency={course.frequency}
        lessonDuration={course.lessonDuration}
        startTime={course.startTime}
        language={course.language}
        materialsLanguage={course.materialsLanguage}
        price={course.price}
        paymentDescription={course.paymentDescription}
        studyProcess={course.studyProcess}
        syllabus={course.syllabus}
        registrationInstructions={course.registrationInstructions}
      />
    </RegularPageFluidBox>
  )
}

export default Course